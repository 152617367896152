<template>
	<el-row>
		<el-col :span="10">
			<div class="left">
				<div class="chiffoLogo">
					<div style="position: relative;"><img src="../../assets/ranqi.png" width="45px" style="padding: 7px 5px 8px 23px;"><span style="font-size: 27px;color: #34B0EC;font-weight: bold;position: absolute;top: 12px;">CHIFFO</span></div>
				</div>
				<div class="logoT logoText">欢迎使用 - 前锋燃气安全卫士</div>
				<div class="logoT logoTextGray">—— 智慧厨房 · 梦想家</div>
			</div>
		</el-col>
		<el-col :span="14">
			<div class="right">
				<el-form :model="login_form" ref="login_form" class="login_form">
					<div class="icon"><img src="../../assets/loginIn.png" width="50px">用户登录</div>
					<h5 style="color: #686FE5;">用户名</h5>
					<el-form-item label="">
						<el-input v-model="login_form.userName" style="color:#686FE5" autocomplete="off"></el-input>
					</el-form-item>
					<h5 style="color: #686FE5;">密码</h5>
					<el-form-item label="">
						<el-input type="password" v-model="login_form.password" style="color:#686FE5" autocomplete="off"></el-input>
					</el-form-item>
					<h5 style="color: #686FE5;">验证码</h5>
					<el-form-item label="">
						<div style="display: flex;flex-direction: row;align-items: center;">
							<el-input style="width: 85px;margin-right: 8px;color:#686FE5" v-model="login_form.verifyCode"></el-input>
							<img @click="verifyCode()" :src="verifyCode_src">
						</div>
					</el-form-item>
					<el-form-item style="margin-top: 40px;">
						<el-button type="primary" style="width: 100%;background-color: #686FE5;border-color: #686FE5;" @click="submitForm('login_form')">登录</el-button>
					</el-form-item>
				</el-form>
				<div class="buttom">
					<a href="https://beian.mps.gov.cn/#/query/websearch?code=51019002006288" style="display: flex;">
						<img src="../../assets/beian.jpg" style="width: 20px;height: 20px;margin-right: 5px;">
						<p>
							川公网安备51019002006288
						</p>
					</a>
					<a href="https://beian.miit.gov.cn/#/Integrated/index" style="display: flex;padding-left: 10px;">
						<p>
							蜀ICP备2023033421号-3
						</p>
					</a>
				</div>
			</div>
		</el-col>
	</el-row>
</template>
<script>
	import {
		asyncRoutes,
		asyncRoutes_children
	} from '../../router/index.js';
	export default {
		data() {
			return {
				login_form: {
					userName: '',
					password: '',
					verifyCode: ''
				},
				verifyCode_src: "",
			};
		},
		beforeDestroy(){
			window.removeEventListener('keydown',this.keyDown,false);
		},
		created() {
			this.verifyCode();
			window.addEventListener( 'keydown' , this.keyDown); 
		},
		beforeRouteLeave( to, from, next ){
		    window.removeEventListener('keydown',this.keyDown,false);
			next();
		},
		methods: {
			keyDown(e){
			    if(e.keyCode == 13){
			       this.submitForm();
				}
			},
			submitForm() {
				this.$api.login(this.login_form).then(res => {
					if (res.code == 200) {
						this.$vuex('token', `${res.data.tokenHead} ${res.data.token}`);
						localStorage.setItem('userName', this.login_form.userName)
						this.initialization(this.login_form.userName);
					}else{
						this.$alert('<strong style="color:red">'+ res.message +'</strong>', '登录提示', {
							dangerouslyUseHTMLString: true
						});
					}
				}).catch(res =>{
					this.verifyCode()
				});
			},
			resetForm(formName) {
				this.$refs.login_form.resetFields();
			},
			verifyCode() {
				this.verifyCode_src = this.$baseURL + '/verifyCode?=' + Math.random();
			},
			initialization(userName) {
				this.$api.initialization(userName).then(res => {
					if (res.code == 200) {
						if( res.data.user.isAdmin == 0 ){
							this.$message({
								type: 'error',
								message: '非PC端用户，请确认当前用户！'
							});
							return
						}
						this.$message({
							type: 'success',
							message: '登录成功！'
						});
						let menuList = this.$common.menuList( res.data.menuList );
						res.data.user.menuList = res.data.menuList;
						if (res.data.user.isIndividual == 1) {
							res.data.user.alarmMessageList = res.data.alarmMessageList;
							res.data.user.is_read = false;
						}
						res.data.user.is_screen = menuList[1];
						localStorage.setItem('is_screen', menuList[1] == false ? 0 : 1)
						this.$vuex('menuList', menuList[0]);
						this.$vuex('user_info', res.data.user);
						if( this.user_info.is_screen ){
							this.$router.push({
								path: "/screen"
							});
						}else{
							this.$router.push({
								path: menuList[0][0].children[0].path,
							});
						}
					}
				});
			},
		}
	}
</script>
<style scoped="scoped">
	.left{
		height: 100vh;
		overflow: hidden;
		position: relative;
		min-width: 400px;
		background: linear-gradient(135deg, #15d8f1 , #6b6be4);
		user-select: none;
	}
	.chiffoLogo{
		position: absolute;
		top: 300px;
		left: 100px;
		border-radius: 30px;
		background-color: #ffffff;
		width: 200px;
		height: 60px;
	}
	.logoT{
		position: absolute;
		left: 180px;
		font-weight: bold;
	}
	.logoText{
		top: 365px;
		color: #ffffff;
		font-size: 35px;
	}
	.logoTextGray{
		top: 410px;
		color: #b5bfdd;
		font-size: 16px;
	}
	.right{
		height: 100vh;
		overflow: hidden;
		position: relative;
		background-color: #DFECFF;
		/* background: linear-gradient(135deg, #15d8f1 , #6b6be4); */
		user-select: none;
	}
	.login_form{
		position: absolute;
		left: 35%;
		top:20%;
		width: 300px;
	}
	h5{
		margin-bottom: 13px;
		color: #BDBDBD;
		font-size: 16px;
		font-weight: normal;
	}
	.buttom{
		text-align: center;
		position: absolute;
		background-color: #DFECFF;
		height: 40px;
		line-height: 20px;
		bottom: 0;
		display: flex;
		justify-content: center;
		width: 100%;
	}
	a{
		text-decoration: none;
	}
	.buttom>a>p{
		color: #666666;
		font-size: 12px;
		text-align: center;
	}
	.icon{
		font-size: 26px;
		margin-left: 20%;
		color:#6b6be4;
		margin-bottom: 20px;
	}
</style>